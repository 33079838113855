<!-- Page Banner -->
<div class="page-banner-area item-bg1">
    <div class="container">
        <div class="page-banner-content">
            <h2>FAQ</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>FAQ</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- FAQ -->
<section class="faq-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>FAQ</span>
            <h2>Asked Questions</h2>
            <div class="bar"></div>
        </div>

        <div class="faq-accordion">
            <ul class="accordion">
                <li class="accordion-item">
                    <a class="accordion-title active" href="javascript:void(0)"><i class='bx bx-plus'></i> What is digital marketing?</a>
                    <p class="accordion-content show">Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                </li>
                
                <li class="accordion-item">
                    <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-plus'></i> What does a digital marketer do?</a>
                    <p class="accordion-content">Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                </li>
                
                <li class="accordion-item">
                    <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-plus'></i> Would my business benefit from digital marketing?</a>
                    <p class="accordion-content">Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                </li>
                
                <li class="accordion-item">
                    <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-plus'></i> What’s the biggest mistake digital marketers make?</a>
                    <p class="accordion-content">Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                </li>

                <li class="accordion-item">
                    <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-plus'></i> How often should I update my website?</a>
                    <p class="accordion-content">Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                </li>
                
                <li class="accordion-item">
                    <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-plus'></i> What do I need to know about mobile?</a>
                    <p class="accordion-content">Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                </li>

                <li class="accordion-item">
                    <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-plus'></i> What is content marketing?</a>
                    <p class="accordion-content">Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                </li>

                <li class="accordion-item">
                    <a class="accordion-title" href="javascript:void(0)"><i class='bx bx-plus'></i> Do I need a blog?</a>
                    <p class="accordion-content">Digital marketing involves marketing to people using Internet-connected electronic devices, namely computers, smartphones and tablets. Digital marketing focuses on channels such as search engines, social media, email, websites and apps to connect with prospects and customers.</p>
                </li>
            </ul>
        </div>
    </div>
</section>
<!-- End FAQ -->

<!-- FAQ Contact -->
<div class="faq-contact-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Questions</span>
            <h2>Do You Have Any Questions?</h2>
            <div class="bar"></div>
        </div>

        <div class="faq-contact-form">
            <form id="contactForm">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group mb-3">
                            <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group mb-3">
                            <input type="email" name="email" id="email" class="form-control" placeholder="Email">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group mb-3">
                            <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Phone">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group mb-3">
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Subject">
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group mb-3">
                            <textarea name="message" class="form-control" id="message" cols="30" rows="6" placeholder="Your Message"></textarea>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="send-btn">
                            <button type="submit" class="default-btn">Send Message</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End FAQ Contact -->