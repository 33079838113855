<!-- Page Banner -->
<div class="page-banner-area">
    <div class="container">
        <div class="page-banner-content">
            <h2>Blog Two Column</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Blog Two Column</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Blog -->
<section class="blog-area pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="single-blog">
                    <div class="image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-1.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <span>20 March, 2020</span>
                        <h3><a routerLink="/blog-details">7 Great Tips For Earn More Money From Digital Industry</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More <i class='bx bx-chevrons-right'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-blog">
                    <div class="image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-2.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <span>25 March, 2020</span>
                        <h3><a routerLink="/blog-details">Business Data is changing the world’s view towards Green Energy</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More <i class='bx bx-chevrons-right'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-blog">
                    <div class="image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-3.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <span>28 March, 2020</span>
                        <h3><a routerLink="/blog-details">The Billionaire Guide On Design That will Get You Rich</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More <i class='bx bx-chevrons-right'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-blog">
                    <div class="image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-4.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <span>20 March, 2020</span>
                        <h3><a routerLink="/blog-details">Finding the blocks of neighboring fields in a matrix with Python</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More <i class='bx bx-chevrons-right'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-blog">
                    <div class="image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-5.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <span>25 March, 2020</span>
                        <h3><a routerLink="/blog-details">Data into Your Enterprise to Drive Actionable Insights</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More <i class='bx bx-chevrons-right'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-blog">
                    <div class="image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-6.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <span>28 March, 2020</span>
                        <h3><a routerLink="/blog-details">The data-driven approach to understanding your users</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More <i class='bx bx-chevrons-right'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-blog">
                    <div class="image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-7.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <span>20 March, 2020</span>
                        <h3><a routerLink="/blog-details">Conversion Rate Optimization: Understanding the Sales Funnel</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More <i class='bx bx-chevrons-right'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-blog">
                    <div class="image">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-8.jpg" alt="image">
                        </a>
                    </div>
                    <div class="content">
                        <span>25 March, 2020</span>
                        <h3><a routerLink="/blog-details">The State of Local SEO Industry Report 2020, Announced</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More <i class='bx bx-chevrons-right'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/blog-1" class="prev page-numbers"><i class="flaticon-left-arrow"></i></a>
                    <a routerLink="/blog-1" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a routerLink="/blog-1" class="page-numbers">3</a>
                    <a routerLink="/blog-1" class="page-numbers">4</a>
                    <a routerLink="/blog-1" class="next page-numbers"><i class="flaticon-next"></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape1"><img src="assets/img/default-shape/default-shape1.png" alt="image"></div>
        <div class="shape2"><img src="assets/img/default-shape/default-shape2.png" alt="image"></div>
        <div class="shape3"><img src="assets/img/default-shape/default-shape3.png" alt="image"></div>
        <div class="shape4"><img src="assets/img/default-shape/default-shape4.png" alt="image"></div>
    </div>
</section>
<!-- End Blog -->