<!-- Page Banner -->
<div class="page-banner-area item-bg1">
    <div class="container">
        <div class="page-banner-content">
            <h2>Services Three</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Services Three</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Services -->
<section class="services-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-code"></i>
                    </div>
                    <h3><a routerLink="/services-details">Website Development</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incutu  labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-vector"></i>
                    </div>
                    <h3><a routerLink="/services-details">Web Design</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incutu  labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-network"></i>
                    </div>
                    <h3><a routerLink="/services-details">Social Media Marketing</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incutu  labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-search"></i>
                    </div>
                    <h3><a routerLink="/services-details">Content Marketing</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incutu  labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-digital-marketing-1"></i>
                    </div>
                    <h3><a routerLink="/services-details">Digital Media & PPC</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incutu  labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <div class="icon">
                        <i class="flaticon-cpu"></i>
                    </div>
                    <h3><a routerLink="/services-details">Technology</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incutu  labore et dolore magna aliqua.</p>
                    <a routerLink="/services-details" class="services-btn">Read More <i class="flaticon-right-arrow"></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape1"><img src="assets/img/default-shape/default-shape1.png" alt="image"></div>
        <div class="shape2"><img src="assets/img/default-shape/default-shape2.png" alt="image"></div>
        <div class="shape3"><img src="assets/img/default-shape/default-shape3.png" alt="image"></div>
        <div class="shape4"><img src="assets/img/default-shape/default-shape4.png" alt="image"></div>
    </div>
</section>
<!-- End Services -->

<!-- Industries -->
<section class="industries-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Industries We Work For</span>
            <h2>Helping Businesses in All Domains</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-digital-marketing-2"></i>
                    <h3>Digital Marketing</h3>
                    <a routerLink="/services-details"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-bank"></i>
                    <h3>Banking Services</h3>
                    <a routerLink="/services-details"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-graduation-cap"></i>
                    <h3>Education Services</h3>
                    <a routerLink="/services-details"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-stock-market"></i>
                    <h3>Business Consult</h3>
                    <a routerLink="/services-details"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-employee"></i>
                    <h3>Entrepreneur</h3>
                    <a routerLink="/services-details"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-waiter"></i>
                    <h3>Restaurant</h3>
                    <a routerLink="/services-details"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-stethoscope"></i>
                    <h3>Health Services</h3>
                    <a routerLink="/services-details"></a>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-industries-box">
                    <i class="flaticon-user"></i>
                    <h3>Social Network</h3>
                    <a routerLink="/services-details"></a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Industries -->