<!-- Page Banner -->
<div class="page-banner-area item-bg3">
    <div class="container">
        <div class="page-banner-content">
            <h2>Performances: Birds of Prey</h2>
            <ul>
                <li>
                    <a href="index.html">Home</a>
                </li>
                <li>Performances</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Show Schedule -->
<section class="features-area pt-100 pb-70" style="padding-top:70px">
    <div class="container">
        <div>
            <div><p style="font-size:xx-large;font-weight:bolder;padding-bottom:15px">Performance Calendar</p></div>
            <div style="height:150px">
                <div display="block" style="float:left">
                    <a href="https://www.texrenfest.com/" target="_blank">
                        <img src="assets/img/texrenfaire_logo.jpg" alt="image">
                    </a>
                </div>
                <div display="block" style="float:left;margin:10px;font-size:x-large">
                    <div>Weekends October 7, 2023 through November 26, plus Thanksgiving Friday</div>
                    <div>Shows at: 10:30/12:30/2:30/4:30</div>
                    <div>Stage: Falconer's Hearth</div>
                </div>
            </div>
            <div>
                <div>
                    <p>We have a long history with the nation's largest Renaissance Faire, and are thrilled that the birds will
                    be back to perform again this year.</p>
                    <p>More than just Falconry, this complete bird show includes hawks, eagles, owls, and vultures, to name a few.
                    Wild Sky Productions began as an idea to educate the public by presenting live animals in an entertaining format.
                    And entertaining it is! The birds soar and glide above, in front of, in back of, and through the audience.</p>
                </div>
            </div>
        </div>
    </div>
</section>