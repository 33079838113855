<!-- Main Banner -->
<div class="main-banner">
   <div class="block-center" ><img src="assets/img/banner/wild_sky_banner.png" alt="image"></div>
</div>
<!-- End Main Banner -->

<!-- Features -->
<section class="features-area" style="padding-top:30px;padding-bottom:30px">
    <div class="container" id="schedule-anchor">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-features">
                    <div>
                        <a routerLink="performances">
                            <h3><div style="text-align:center">
                                Calendar: Birds of Prey Performances
                            </div></h3>
                            <p>Where to see these magnificent birds up close, and in free flight.</p>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-features">
                    <div>
                        <a routerLink="/about-live-shows">
                            <h3><div style="text-align:center">
                                About Our Live Shows
                            </div></h3>
                            <p>See what makes our shows an experience you can't get anywhere else.</p>
                        </a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- End Features -->

<section class="features-area pt-100 pb-70" style="padding-top:0px">
    <div class="container">
        <div>
            <div><p style="font-size:xx-large;font-weight:bolder;padding-bottom:15px">Upcoming/Ongoing Performances</p></div>
            <div style="height:150px">
                <div display="block" style="float:left">
                    <a href="https://www.texrenfest.com/" target="_blank">
                        <img src="assets/img/texrenfaire_logo.jpg" alt="image">
                    </a>
                </div>
                <div display="block" style="float:left;margin:10px;font-size:x-large">
                    <div>Weekends October 7, 2023 through November 26 & Thanksgiving Friday</div>
                    <div>Shows at: 10:30/12:30/2:30/4:30</div>
                    <div>Stage: Falconer's Hearth</div>
                </div>
            </div>
            <div>
                <div>
                    <p>We have a long history with the nation's largest Renaissance Faire, and are thrilled that the birds will
                    be back to perform again this year.</p>
                    <p>More than just Falconry, this complete bird show includes hawks, eagles, owls, and vultures, to name a few.
                    Wild Sky Productions began as an idea to educate the public by presenting live animals in an entertaining format.
                    And entertaining it is! The birds soar and glide above, in front of, in back of, and through the audience.</p>
                </div>
            </div>
        </div>
    </div>
</section>