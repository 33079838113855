<!-- Page Banner -->
<div class="page-banner-area">
    <div class="container">
        <div class="page-banner-content">
            <h2>Privacy Policy</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Privacy Policy</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Privacy Policy -->
<section class="privacy-policy-area ptb-100">
    <div class="container">
        <div class="privacy-content">
            <h3>1. Our Privacy Policy</h3>
            <p>This site does not collect any personal information about you, nor allow any third party to do so.</p>
        </div>
    </div>
</section>
<!-- End Privacy Policy -->